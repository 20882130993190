/*..........btns..........*/

.btn {
  background-color: #00fee3;
  font-family: 'JetBrains Mono', monospace;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  padding: 1rem 2rem;
  position: relative;
  min-width: 10em;
}

.btn:hover {
  cursor: pointer;
}

.btn:active:before,
.btn:active:after,
.btn:active {
  transform: scale(1.2);
  transition: 0.25s cubic-bezier(0.5, -1, 0.5, 2);
}

.btn:before,
.btn:after {
  width: 4.4em;
  height: 2.95em;
  position: absolute;
  content: '';
  display: inline-block;
  background: #00fee3;
  border-radius: 50%;
  /* transition: transform 0.5s ease; */
  transition: 0.25s cubic-bezier(0.5, -1, 0.5, 2);
  transform: scale(0);
  z-index: -1;
}

.btn:before {
  top: -25%;
  left: 20%;
}

.btn:after {
  bottom: -25%;
  right: 20%;
}

.btn:hover:before,
.btn:hover:after {
  transform: none;
}

.wrapper {
  padding: 1.5rem 0;
  filter: url('#goo');
}

/*...................*/
