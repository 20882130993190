@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;300;400;600;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;600;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;900&display=swap');

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: #111;
  font-family: 'JetBrains Mono', monospace;
  display: flex;
  justify-content: center;
  height: 100%;
}

main {
  margin: 0 auto;
  max-width: 1200px;
  background-color: #fff;
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

summary:hover {
  cursor: pointer;
}

/*..........Headings.........*/

h1,
h2,
h3,
h4 {
  padding: 1rem;
}

h1 {
  text-align: center;
  font-size: 1.5rem;
  padding: 0.3rem;
  padding-right: 7rem;
  background-color: #111;
  font-weight: 300;
  color: #00fee3;
  z-index: 1;
  text-align: left;
  position: absolute;
  transition: 2s ease;
}

h1:hover {
  cursor: pointer;
}

h3 {
  color: #9c9c9c;
  padding-left: 2rem;
}

h4 {
  color: #9c9c9c;
  /* padding-left: 4rem; */
  font-style: italic;
}

main a {
  font-weight: bold;
  text-decoration: none;
  color: #000;
}

.gitLink {
  color: #00fee3;
}

/* Header */
.logo {
  font-family: 'JetBrains Mono', monospace;
}

header a {
  text-decoration: none;
  color: white;
}

header a:hover {
  color: #00fee3;
}

nav ul {
  text-align: right;
}

nav ul li {
  display: inline-block;
  margin-right: 1rem;
}

section {
  padding: 1rem;
}

section:nth-child(even) {
  background: linear-gradient(to bottom, white, #eaeaeac8);
}

/*...........Banner................*/
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  background-color: #111;
  height: 70vh;
  overflow: hidden;
}

.square {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  background: #1d1d1d;
  margin: 1px;
  box-shadow: 0 0 2px #000;
  transition: 5s ease;
}

.square:hover {
  transition-duration: 0s;
}

/* Hand-wave animation */

.hand-wave {
  display: inline-block;
  animation: shake 0.3s;
  animation-iteration-count: 6;
}

.hand-wave:hover {
  animation: shake 0.3s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: rotate(0deg) scale(1.5);
  }
  50% {
    transform: rotate(-10deg) scale(1.5);
  }
  100% {
    transform: rotate(0deg) scale(1.5);
  }
}

/*............Projects...............*/
.projects .git {
  background-color: #00fee3;
  padding: 0.3rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  max-width: 800px;
  width: fit-content;
}

.container-projects,
.container-licenses {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.project {
  width: 200px;
  flex: 1;
  flex-wrap: wrap;
  height: 420px;
  /* border: 1px solid gray; */
  border-radius: 10px;
  margin: 0.5px;
  position: relative;
  z-index: 3;
}

.project h3 {
  color: #111;
}

.project .container-img {
  height: 40vh;
  /* border-bottom: 2px solid gray; */
  border-radius: 10px;
  overflow: hidden;
}

.project .container-img img {
  filter: saturate(0);
  height: 100%;
}

.project .content {
  /* background-image: linear-gradient(rgb(219, 219, 219), #fff); */
  font-size: 0.8rem;
  height: 240px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  /* border: 1px solid rgb(225, 225, 225); */
  border-radius: 0 0 10px 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.project .content p {
  font-style: italic;
  font-weight: 100;
}

.project:hover .content {
  /* transform: translate(0, 190px); */
  transition: all 1s ease;
}
.project:hover {
  transform: scale(1.02);
  transition: all 1s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.project:hover img {
  transform: scale(1.1);
  transition: all 1s ease;
  filter: saturate(1);
}

.btn-secondary {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  font-family: 'JetBrains Mono', monospace;
  border: 1px solid #111;
  border-radius: 10px;
  background-color: #fff;
}

/*............About Me...............*/
/*............Who I am...............*/
.whoIAm {
  display: flex;
}

.whoIAm .leftSide {
  flex: 1;
}

.profilePic {
  width: 100%;
  height: fit-content;
  margin-top: 10%;
  text-align: center;
  position: relative;
}

.profilePic::before {
  content: '';
  background-color: #00fee3;

  height: 60%;
  width: 60%;
  position: absolute;
  top: -5%;
  left: 5%;
  display: inline-block;
}

.whoIAm .leftSide img {
  width: 80%;
  /* justify-self: center; */
  filter: brightness(100%);
  border: 1rem solid #ffffff;
}

.whoIAm .rightSide {
  flex: 2;
}

.btn-container {
  text-align: center;
  margin-top: 1rem;
}

/*............Skills...............*/
.skills {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flexColumn {
  flex: 1;
}

.skills-category {
  text-align: left;
}

.skills ul {
  font-size: 14px;
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.skills ul li:before {
  content: '#';
  position: absolute;
  left: 0;
  color: #9c9c9c;
}

/*............Licenses...............*/

.previewOn {
  width: 100vw !important;
  /* width: fit-content !important; */
  margin: 0 auto;
  height: calc(100vh - 68px);
  /* height: fit-content !important; */
}

.cardPreview {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed;
  top: 68px;
  left: 0;
  z-index: 5;
  margin: 0 auto;
}
/*............Footer...............*/
#contacts {
  margin-bottom: 1rem;
}
#contacts a {
  margin-right: 2rem;
}

footer {
  background-color: #111;
  text-align: center;
  color: #fff;
  padding-bottom: 2rem;
}

footer h2 {
  text-align: left;
}

footer fieldset {
  margin: 0 auto;
  border: none;
}

footer fieldset label {
  font-weight: 200;
  display: inline-block;
  width: 8em;
  text-align: left;
}

footer fieldset input {
  display: inline-block;
  width: 30vw;
  border: transparent;
  border-radius: 5px;
  padding: 0.5em;
}

footer fieldset textarea {
  width: 30vw;
  vertical-align: top;
  border-radius: 5px;
  padding: 0.5em;
  height: 100px;
  min-height: fit-content;
}

.mailStatus {
  display: inline-block;
  color: black;
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem 2rem;
  margin-bottom: 0.5rem;
}

.Fail {
  background-color: red;
}

.Success {
  background-color: #00fee3;
}

/* Mobile phones design */
@media (max-width: 530px) {
  body {
    font-size: 0.9rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
  }
  /*............About Me...............*/
  /*............Who I am...............*/
  .whoIAm {
    display: block !important;
  }

  .whoIAm .leftSide {
    width: 100%;
  }

  .whoIAm .rightSide {
    font-size: 0.9rem;
  }
  /*............Footer...............*/
  footer label {
    display: block !important;
    padding-bottom: 0.5rem;
  }

  footer fieldset textarea,
  footer fieldset input {
    width: 100%;
  }

  /*............Licenses...............*/

  .previewOn {
    border: 1px solid red;
    width: 100vw !important;
    height: 50vh;
  }

  .cardPreview {
    width: 100vw !important;
    height: auto;
    border: 1px solid green;
    top: 56px;
  }
}

/* Big screens */
@media (min-width: 1200px) {
  .previewOn {
    max-width: 1200px;
  }

  .cardPreview {
    max-width: 1200px;
    left: calc((100vw - 1220px) / 2);
  }
}
